import redbotsApi from "../api/apiService";

export const getAvailableMethods = async () => {
    try {
        const response = await redbotsApi.get('/api/get-methods');
        console.log(response.data.methods);
        return response.data.methods;
    } catch (error) {
        return [];
    }
};

export const getRunningAttacks = async () => {
    try {
        const response = await redbotsApi.get('/api/get-attacks');
        console.log(response.data.attacks);
        return response.data.attacks;
    } catch (error) {
        return [];
    }
};