import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import StatusIndicator from './components/StatusIndicator';
import SlotUsage from './components/SlotUsage';
import AttackForm from './components/AttackForm';
import AttackItem from './components/AttackItem';
import Modal from './components/Modal';
import AdvancedParamsModal from './components/AdvancedParamsModal';

import { handleSendAttack as sendAttackHandler } from './utils/attack';

function Dashboard({ translations }) {
    const [targetUrl, setTargetUrl] = useState('https://crypto-pharmacy.io/');
    const [slotUsed] = useState(10);
    const [slotMax] = useState(10);
    const [runningAttackData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAdvancedParamsOpen, setIsAdvancedParamsOpen] = useState(false);
    const [advancedParams, setAdvancedParams] = useState({
        req: 'GET',
        origin: 'ALL',
        rate: 64,
        userAgent: 'ALL',
        threads: 1,
        postBody: '',
        postBodyFormat: 'form',
    });
    const [attackType, setAttackType] = useState('StormKiller');
    const [attackTime, setAttackTime] = useState(120);

    const handleSendAttack = () => {
        sendAttackHandler(
            targetUrl,
            translations,
            {...advancedParams, type: attackType, time: attackTime}
        ).then(r =>r);
    };

    const showMoreItems = () => {
        setIsModalOpen(true);
    };

    const showAdvancedParams = () => {
        setIsAdvancedParamsOpen(true);
    };

    const handleAdvancedParamsChange = (newParams) => {
        setAdvancedParams(newParams);
    };

    return (
        <div className="flex flex-col w-full h-screen bg-[#1a1c33] text-white">
            <Helmet>
                <title>{translations.dashboard.pageDashboardTitle}</title>
            </Helmet>
            <div className="flex justify-center items-center w-full h-full p-8">
                <div className="bg-[#272d47] rounded-lg p-8 shadow-lg shadow-black"
                     style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'}}>
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-3xl font-bold">{translations.dashboard.runningAttacks}</h1>
                        <StatusIndicator translations={translations}/>
                    </div>

                    <div className="flex flex-col gap-6">
                        <div className="grid grid-cols-1 gap-4">
                            {runningAttackData.length === 0 && (
                                <div className="text-center text-white">
                                    <h2 className="text-2xl font-bold">
                                        {translations.dashboard.noAttackRunning}
                                    </h2>
                                </div>
                            )}
                            {runningAttackData.slice(0, 1).map((data, index) => (
                                <AttackItem key={index} data={data} translations={translations}/>
                            ))}
                        </div>
                        {runningAttackData.length > 2 && (
                            <button
                                className="bg-[#705ec8] hover:bg-[#6150ba] rounded-md px-6 py-3 text-lg font-bold self-center"
                                onClick={showMoreItems}
                            >
                                {translations.dashboard.viewMore}
                            </button>
                        )}

                        <hr className="my-2 border-b-2 border-white-500"/>
                        <AttackForm
                            targetUrl={targetUrl}
                            setTargetUrl={setTargetUrl}
                            translations={translations}
                            handleSendAttack={handleSendAttack}
                            showAdvancedParams={showAdvancedParams}
                            setAttackType={setAttackType}
                            setAttackTime={setAttackTime}
                        />
                        <SlotUsage slotUsed={slotUsed} slotMax={slotMax} translations={translations}/>
                    </div>
                </div>
            </div>
            <Toaster/>

            <Modal isOpen={isModalOpen} translations={translations} onClose={() => setIsModalOpen(false)}>
                <div className="grid grid-cols-1 gap-4">
                    {runningAttackData.map((data, index) => (
                        <AttackItem key={index} data={data} translations={translations} />
                    ))}
                </div>
            </Modal>

            <AdvancedParamsModal
                isOpen={isAdvancedParamsOpen}
                translations={translations}
                onClose={() => setIsAdvancedParamsOpen(false)}
                params={advancedParams}
                onParamsChange={handleAdvancedParamsChange}
            />
        </div>
    );
}

export default Dashboard;
