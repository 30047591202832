import React, { useState, useEffect } from 'react';

const API_STATUS = {
  IDLE: 'idle',
  SUCCESS: 'success',
  ERROR: 'error',
};

const StatusIndicator = ({ translations }) => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  useEffect(() => {
    let ws;

    const connectWebSocket = () => {
      ws = new WebSocket('ws://api.redbots.cc/');

      ws.onopen = () => setApiStatus(API_STATUS.SUCCESS);
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setApiStatus(data.status === 'online' ? API_STATUS.SUCCESS : API_STATUS.ERROR);
      };
      ws.onerror = () => setApiStatus(API_STATUS.ERROR);
      ws.onclose = () => {
        setApiStatus(API_STATUS.ERROR);
        setTimeout(connectWebSocket, 1000); // Retry connection after 1 second
      };
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleMouseOver = () => setShowInfoTooltip(true);
  const handleMouseOut = () => setShowInfoTooltip(false);

  const getStatusColorClass = () => {
    switch (apiStatus) {
      case API_STATUS.SUCCESS:
        return 'bg-green-500';
      case API_STATUS.ERROR:
        return 'bg-red-500';
      default:
        return 'bg-gray-400';
    }
  };

  const getStatusMessage = () => {
    switch (apiStatus) {
      case API_STATUS.SUCCESS:
        return translations.dashboard.apiStatusOnline.replace(/ /g, '\u00A0');
      case API_STATUS.ERROR:
        return translations.dashboard.apiStatusOffline.replace(/ /g, '\u00A0');
      default:
        return translations.dashboard.apiStatusIdle.replace(/ /g, '\u00A0');
    }
  };

  return (
    <div
      className="flex gap-4 relative"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className={`rounded-full ${getStatusColorClass()}`}
        style={{ width: '1.5rem', height: '1.5rem' }}
      />

      {showInfoTooltip && (
        <div
          className={`absolute top-8 right-0 px-4 py-2 rounded-md shadow-md text-white ${getStatusColorClass()}`}
          style={{ zIndex: 10 }}
        >
          {getStatusMessage()}
        </div>
      )}
    </div>
  );
};

export default StatusIndicator;