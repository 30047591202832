import React, { useState, useEffect } from 'react';
import { getAvailableMethods } from '../utils/dataFetcher';

function AttackForm({
                        targetUrl,
                        setTargetUrl,
                        translations,
                        handleSendAttack,
                        showAdvancedParams,
                        setAttackType,
                        setAttackTime,
                    }) {
    const [targetUrlError, setTargetUrlError] = useState(false);
    const [type, setType] = useState('StormKiller'); // Initial method
    const [time, setTime] = useState(120);
    const [availableMethods, setAvailableMethods] = useState([]); // State to store methods

    const validateTargetUrl = (url) => {
        const urlRegex = /^(http|https):\/\/[a-z0-9.-]+\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        return urlRegex.test(url);
    };

    const handleTargetUrlChange = (e) => {
        const newTargetUrl = e.target.value;
        setTargetUrl(newTargetUrl);
        setTargetUrlError(!validateTargetUrl(newTargetUrl));
    };

    const handleTypeChange = (e) => {
        const newType = e.target.value;
        setType(newType);
        setAttackType(newType);
    };

    const handleTimeChange = (e) => {
        const newTime = parseInt(e.target.value, 10);
        setTime(newTime);
        setAttackTime(newTime);
    };

    useEffect(() => {
        getAvailableMethods().then((methods) => {
            setAvailableMethods(methods);
        });
    }, []);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
                <label className="text-xl font-bold text-center" htmlFor="targetUrl">
                    {translations.dashboard.targetUrl}
                </label>
                <input
                    className={`bg-[#272d47] border rounded-md px-4 py-2 text-lg ${
                        targetUrlError ? 'border-red-500' : ''
                    }`}
                    type="text"
                    id="targetUrl"
                    value={targetUrl}
                    onChange={handleTargetUrlChange}
                />
                {targetUrlError && (
                    <div className="text-red-500 text-sm mt-1">
                        {translations.dashboard.targetUrlError}
                    </div>
                )}
            </div>

            <div className="flex flex-row gap-4 items-center justify-center">
                {/* Method Section */}
                <div className="flex flex-col gap-4 w-1/2">
                    <label className="text-2xl font-bold text-center" htmlFor="method">
                        {translations.dashboard.method}
                    </label>
                    <select
                        className="bg-[#272d47] border rounded-md px-4 py-2 text-lg appearance-none" // Add appearance-none
                        id="method"
                        value={type}
                        onChange={handleTypeChange}
                    >
                        {availableMethods.map((category, categoryIndex) => (
                            <optgroup key={categoryIndex} label={category.name}>
                                {category.methods.map((method, methodIndex) => (
                                    <option key={methodIndex} value={method}>
                                        {method}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                </div>

                {/* Time Section */}
                <div className="flex flex-col gap-4 w-1/2">
                    <label className="text-2xl font-bold text-center" htmlFor="time">
                        {translations.dashboard.time}
                    </label>
                    <input
                        className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                        type="number"
                        id="time"
                        value={time}
                        onChange={handleTimeChange}
                    />
                </div>
            </div>

            <div className="flex gap-6 mt-6 justify-center items-center">
                <button
                    className="bg-[#705ec8] hover:bg-[#6150ba] rounded-md px-6 py-3 text-lg font-bold"
                    onClick={handleSendAttack}
                >
                    {translations.dashboard.sendAttack}
                </button>
                <button className="bg-[#ef4b4b] hover:bg-[#e54343] rounded-md px-6 py-3 text-lg font-bold">
                    {translations.dashboard.stopAll}
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-600 rounded-md px-6 py-3 text-lg font-bold"
                    onClick={showAdvancedParams}
                >
                    {translations.dashboard.advancedParamsButton}
                </button>
            </div>
        </div>
    );
}

export default AttackForm;