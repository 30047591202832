import React from 'react';

const Modal = ({ isOpen, onClose, children, translations }) => {
    if (!isOpen) return null;

    return (
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out ${
                isOpen ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ display: isOpen ? 'flex' : 'none' }}
        >
            <div
                className={`relative bg-[#272d47] rounded-lg p-8 shadow-lg shadow-black w-11/12 max-w-4xl overflow-y-auto max-h-full transition-all duration-300 ease-in-out transform ${
                    isOpen ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-4 opacity-0 scale-95'
                }`}
            >
                <button
                    className="absolute top-4 right-4 text-white hover:text-gray-300 rounded-full p-2 flex items-center justify-center"
                    onClick={onClose}
                >
                    <div className="bg-red-500 rounded-full p-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </button>
                <h2 className="text-3xl font-bold mb-4 text-center text-white">
                    {translations.dashboard.listallattacks}
                </h2>
                {children}
            </div>
        </div>
    );
};

export default Modal;