import React, {useState} from 'react';

function SlotUsage({slotUsed, slotMax, translations}) {
    const availableSlots = slotMax - slotUsed;
    const percentage = (availableSlots / slotMax) * 100;

    const [showPercentage, setShowPercentage] = useState(false);

    return (
        <div className="flex flex-col gap-4 mt-6" onMouseEnter={() => setShowPercentage(true)}
             onMouseLeave={() => setShowPercentage(false)}>
            <label className="text-xl font-bold">{translations.dashboard.slotUsage}</label>
            <div
                className="bg-[#272d47] rounded-full h-4 w-full overflow-hidden relative border border-white"
            >
                <div
                    className={`bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 rounded-full h-4 transition-all duration-500`}
                    style={{width: `${percentage}%`}}
                />
                {showPercentage && (
                    <div
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-md text-xs">
                        {percentage === 100 ? translations.dashboard.fullyUsed : `${percentage.toFixed(2)}%`}
                    </div>
                )}
            </div>
            <div className="flex justify-between items-center text-sm">
                {availableSlots === 0 ? (
                    <span>{translations.dashboard.availableSlots.replace('{availableSlots}', availableSlots).replace('{slotMax}', slotMax)}</span>
                ) : (
                    <span className="text-red-500 font-bold">{translations.dashboard.slotsFullyUsed}</span>
                )}
            </div>
        </div>
    );
}

export default SlotUsage;