import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./login";
import Dashboard from "./dashboard";
import PrivateRoute from "./PrivateRoute";

import en from "./translations/en.json";
import ru from "./translations/ru.json";
import ch from "./translations/ch.json";

function App() {
  const [language, setLanguage] = useState("en");
  const [translations, setTranslations] = useState(en);
  const [flags, setFlags] = useState({
    en: "",
    ru: "",
    ch: ""
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loadTranslations = useCallback(async () => {
    try {
      let data;
      if (language === "en") {
        data = { ...en };
      } else if (language === "ru") {
        data = { ...ru };
      } else if (language === "ch") {
        data = { ...ch };
      }
      setTranslations(data);
    } catch (error) {
      console.error("Erreur de chargement des traductions:", error);
    }
  }, [language]);

  const loadFlags = useCallback(async () => {
    try {
      const response = await fetch('https://api.redbots.cc/api/flags/all');
      const countries = await response.json();
      const newFlags = {
        en: countries.find(c => c.cca2 === 'GB')?.flags?.png || '',
        ru: countries.find(c => c.cca2 === 'RU')?.flags?.png || '',
        ch: countries.find(c => c.cca2 === 'CN')?.flags?.png || '',
      };
      setFlags(newFlags);
    } catch (error) {
      console.error("Erreur de chargement des drapeaux:", error);
    }
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
    loadTranslations();
    loadFlags();
  }, [loadTranslations, loadFlags]);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    loadTranslations();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
      <BrowserRouter>
        <AppContent
            language={language}
            translations={translations}
            flags={flags}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            onLanguageChange={handleLanguageChange}
        />
      </BrowserRouter>
  );
}

function AppContent({
                      language,
                      translations,
                      flags,
                      isAuthenticated,
                      setIsAuthenticated,
                      onLanguageChange
                    }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
      <div className="flex flex-col w-full h-screen bg-[#1a1c33] text-white items-center justify-center">
        <div className="fixed top-0 left-0 w-full bg-[#272d47] p-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src="/logo.svg" alt="Logo" className="w-16 h-16 mr-4" />
            <h1 className="text-2xl font-bold">StormHub</h1>
          </div>
          <div className="flex">
            {['en', 'ru', 'ch'].map((lang) => (
                <button
                    key={lang}
                    className="bg-[#333a5c] px-4 py-2 rounded-full text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 mr-4"
                    onClick={() => onLanguageChange(lang)}
                >
                  <img src={flags[lang]} alt={`${lang} Flag`} className="w-6 h-6 rounded-full inline-block" />
                </button>
            ))}
          </div>
          {isAuthenticated && (
              <button
                  className="bg-red-500 px-6 py-3 rounded-full text-lg font-bold focus:outline-none focus:ring-2 focus:ring-red-700"
                  onClick={handleLogout}
              >
                {translations.login.logout ? translations.login.logout : "Logout"}
              </button>
          )}
        </div>

        <Routes>
          <Route path="/login" element={<Login translations={translations} setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/dashboard" element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<Dashboard translations={translations} />} />
          </Route>
        </Routes>
      </div>
  );
}

export default App;