import React, {useState, useEffect} from 'react';
import {toast} from 'react-hot-toast';

const AdvancedParamsModal = ({isOpen, onClose, translations, onParamsChange}) => {
    const [params, setParams] = useState({
        req: 'GET',
        origin: 'ALL',
        rate: 64,
        userAgent: 'ALL',
        threads: 1,
        postBody: '',
        postBodyFormat: 'form',
    });

    useEffect(() => {
        onParamsChange(params);
    }, [params, onParamsChange]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setParams((prevParams) => ({...prevParams, [name]: value}));
    };

    const handleImport = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.json';

        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const data = JSON.parse(e.target.result);
                    setParams(data);
                    toast.success(translations.dashboard.importSuccess, {
                        duration: 2000,
                        position: 'top-center',
                        style: {
                            background: '#272d47',
                            color: '#fff',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            fontSize: '16px',
                        },
                    });
                } catch (error) {
                    toast.error(translations.dashboard.importError, {
                        duration: 2000,
                        position: 'top-center',
                        style: {
                            background: '#272d47',
                            color: '#fff',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            fontSize: '16px',
                        },
                    });
                }
            };

            reader.onerror = () => {
                toast.error(translations.dashboard.importFileError, {
                    duration: 2000,
                    position: 'top-center',
                    style: {
                        background: '#272d47',
                        color: '#fff',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        fontSize: '16px',
                    },
                });
            };

            reader.readAsText(file);
        });

        fileInput.click();
    };

    const handleExport = () => {
        try {
            const blob = new Blob([JSON.stringify(params)], {type: 'application/json'});
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'preset.json';
            link.click();

            URL.revokeObjectURL(url);
            toast.success(translations.dashboard.exportSuccess, {
                duration: 2000,
                position: 'top-center',
                style: {
                    background: '#272d47',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '16px',
                },
            });
        } catch (error) {
            toast.error(translations.dashboard.exportError, {
                duration: 2000,
                position: 'top-center',
                style: {
                    background: '#272d47',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '16px',
                },
            });
        }
    };

    const handleFormatChange = (e) => {
        const {value} = e.target;
        setParams({...params, postBodyFormat: value});
    };

    const getPlaceholder = () => {
        if (params.postBodyFormat === 'form') {
            return 'username=x&password=x';
        } else if (params.postBodyFormat === 'json') {
            return '{"username": "x", "password": "x"}';
        }
        return '';
    };

    if (!isOpen) return null;

    return (
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out ${
                isOpen ? 'opacity-100' : 'opacity-0'
            }`}
            style={{display: isOpen ? 'flex' : 'none'}}
        >
            <div
                className={`relative bg-[#272d47] rounded-lg p-8 shadow-lg shadow-black w-11/12 max-w-4xl overflow-y-auto max-h-full transition-all duration-300 ease-in-out transform ${
                    isOpen ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-4 opacity-0 scale-95'
                }`}
            >
                <button
                    className="absolute top-4 right-4 text-white hover:text-gray-300 rounded-full p-2 flex items-center justify-center"
                    onClick={onClose}
                >
                    <div className="bg-red-500 rounded-full p-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </button>
                <h2 className="text-3xl font-bold mb-4 text-center text-white">
                    {translations.dashboard.advancedParams}
                </h2>

                <div className="grid grid-cols-2 gap-4">
                    {/* Paramètre 'req' */}
                    <div className="flex flex-col gap-4">
                        <label className="text-xl font-bold text-center" htmlFor="req">
                            {translations.dashboard.req}
                        </label>
                        <select
                            className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                            id="req"
                            name="req"
                            value={params.req}
                            onChange={handleInputChange}
                        >
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="HEAD">HEAD</option>
                        </select>
                    </div>
                    {/* Paramètre 'origin' */}
                    <div className="flex flex-col gap-4">
                        <label className="text-xl font-bold text-center" htmlFor="origin">
                            {translations.dashboard.origin}
                        </label>
                        <select
                            className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                            id="origin"
                            name="origin"
                            value={params.origin}
                            onChange={handleInputChange}
                        >
                            <option value="ALL">World</option>
                            <option value="US">United States</option>
                            <option value="EU">Europe</option>
                        </select>
                    </div>
                    {/* Paramètre 'userAgent' */}
                    <div className="flex flex-col gap-4">
                        <label className="text-xl font-bold text-center" htmlFor="userAgent">
                            {translations.dashboard.userAgent}
                        </label>
                        <select
                            className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                            id="userAgent"
                            name="userAgent"
                            value={params.userAgent}
                            onChange={handleInputChange}
                        >
                            <option value="ALL">Mixed</option>
                            <option value="Chrome">Chrome</option>
                            <option value="Firefox">Firefox</option>
                            <option value="Spider">Spider</option>
                        </select>
                    </div>
                    {/* Paramètre 'rate' */}
                    <div className="flex flex-col gap-4">
                        <label className="text-xl font-bold text-center" htmlFor="rate">
                            {translations.dashboard.rate}
                        </label>
                        <input
                            className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                            type="number"
                            id="rate"
                            name="rate"
                            value={params.rate}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* Paramètre 'threads' */}
                    <div className="flex flex-col gap-4">
                        <label className="text-xl font-bold text-center" htmlFor="threads">
                            {translations.dashboard.threads}
                        </label>
                        <input
                            className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                            type="number"
                            id="threads"
                            name="threads"
                            value={params.threads}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* Paramètre 'postBody' (Affiché seulement si 'req' est 'POST') */}
                    {params.req === 'POST' && (
                        <div className="flex flex-col gap-4">
                            <label className="text-xl font-bold text-center" htmlFor="postBody">
                                {translations.dashboard.postBody}
                            </label>
                            <div className="flex items-center gap-2">
                                <input
                                    className="bg-[#272d47] border rounded-md px-4 py-2 text-lg flex-1"
                                    type="text"
                                    id="postBody"
                                    name="postBody"
                                    value={params.postBody}
                                    onChange={handleInputChange}
                                    placeholder={getPlaceholder()}
                                />
                                <select
                                    className="bg-[#272d47] border rounded-md px-4 py-2 text-lg"
                                    id="postBodyFormat"
                                    name="postBodyFormat"
                                    value={params.postBodyFormat}
                                    onChange={handleFormatChange}
                                >
                                    <option value="form">FORM</option>
                                    <option value="json">JSON</option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                        onClick={handleImport}
                    >
                        {translations.dashboard.import}
                    </button>
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleExport}
                    >
                        {translations.dashboard.export}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdvancedParamsModal;
