import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MAX_ATTEMPTS = 3;
const API_ENDPOINT = 'https://api.redbots.cc/api/validate-pin';

function Login({ translations }) {
  const [pin, setPin] = useState(['', '', '', '']);
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [apiDown, setApiDown] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (attempts >= MAX_ATTEMPTS) {
      setDisabled(true);
    }
  }, [attempts]);

  const handleInputChange = (index, event) => {
    const newDigit = event.target.value;
    if (newDigit.match(/^[0-9]$/)) {
      const updatedPin = [...pin];
      updatedPin[index] = newDigit;
      setPin(updatedPin);

      if (newDigit && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const updatedPin = [...pin];

      if (updatedPin[index]) {
        updatedPin[index] = '';
        setPin(updatedPin);
      } else if (index > 0) {
        updatedPin[index - 1] = '';
        setPin(updatedPin);
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fullPin = pin.join('');

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pin: fullPin, attempts }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        window.location.href = '/dashboard';
      } else if (response.status === 523) {
        setApiDown(true);
      } else {
        const data = await response.json();
        setError(true);
        setAttempts(data.attempts);
        setPin(['', '', '', '']);
        inputRefs.current[0].focus();
      }
    } catch (error) {
      setApiDown(true);
    }
  };

  const renderPinInput = () => {
    return pin.map((digit, index) => (
      <input
        key={index}
        ref={el => inputRefs.current[index] = el}
        data-index={index}
        className={`bg-[#272d47] border-2 rounded-full w-24 h-24 text-center text-2xl font-bold ${error ? 'border-red-500' : 'border-white'}`}
        type="text"
        value={digit}
        onChange={(event) => handleInputChange(index, event)}
        maxLength="1"
        inputMode="numeric"
        onKeyDown={(event) => handleKeyDown(index, event)}
        disabled={disabled}
        style={{ outline: 'none' }}
      />
    ));
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="text-center mb-8">
          <p className="text-red-500 font-bold text-xl">
            {attempts < MAX_ATTEMPTS ? (
              translations.login.invalidPin.replace('{attempts}', MAX_ATTEMPTS - attempts) 
            ) : (
              translations.login.temporarilyBanned
            )}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col w-full h-screen bg-[#1a1c33] text-white items-center justify-center">
      <Helmet>
        <title>{translations.login.pageLoginTitle}</title>
      </Helmet>

      <div className="bg-[#272d47] rounded-lg p-16 shadow-lg shadow-black">
        <h1 className="text-5xl font-bold mb-12 text-center">{translations.login.pinLogin}</h1>

        {apiDown && (
          <div className="text-center mb-8">
            <p className="text-red-500 font-bold text-xl">{translations.login.serviceUnavailable}</p>
          </div>
        )}

        {!apiDown && (
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center gap-8 mb-12">
              {renderPinInput()}
            </div>

            {renderError()}

            <div className="flex justify-center">
              <button
                className={`bg-purple-500 hover:bg-purple-600 rounded-md px-12 py-4 text-xl font-bold ${disabled ? 'bg-gray-500 cursor-not-allowed' : ''}`}
                type="submit"
                disabled={disabled}
              >
                {translations.login.login}
              </button>
            </div>
          </form>
        )}

        <div className="flex justify-center mt-8">
          <a href="https://t.me/redbots" target="_blank" rel="noopener noreferrer">
            <button className="bg-blue-500 hover:bg-blue-600 rounded-md px-12 py-4 text-xl font-bold">
              {translations.login.joinTelegram}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;