import React from 'react';

const AttackItem = ({ data, translations }) => {

    const cleanUrl = (url) => {
        return url.replace(/^(https?:\/\/)?(www\.)?/, '');
    };

    if (!data) {
        return (
            <div className="flex justify-center items-center h-full">
                <h2 className="text-2xl font-bold text-gray-500">
                    {translations.dashboard.noAttackRunning}
                </h2>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-1 md:grid-cols-5 md:gap-3 items-center">
                <div className="flex flex-col gap-3 items-center">
                    <label className="text-base font-bold">{translations.dashboard.method}</label>
                    <div className="bg-[#272d47] border rounded-md px-3 py-2 text-base"
                         style={{backgroundColor: '#272d47', padding: '10px 12px', cursor: 'default', minHeight: '32px'}}>
                        {data.method}
                    </div>
                </div>

                <div className="flex flex-col gap-3 items-center">
                    <label className="text-base font-bold">{translations.dashboard.target}</label>
                    <div className="bg-[#272d47] border rounded-md px-3 py-2 text-base"
                         style={{backgroundColor: '#272d47', padding: '10px 12px', cursor: 'default', minHeight: '32px'}}>
                        {cleanUrl(data.url)}
                    </div>
                </div>

                <div className="flex flex-col gap-3 items-center">
                    <label className="text-base font-bold">{translations.dashboard.duration}</label>
                    <div className="bg-[#272d47] border rounded-md px-3 py-2 text-base"
                         style={{backgroundColor: '#272d47', padding: '10px 12px', cursor: 'default', minHeight: '32px'}}>
                        {data.duration}
                    </div>
                </div>

                <div className="flex flex-col gap-3 items-center">
                    <label className="text-base font-bold">{translations.dashboard.kill}</label>
                    <button className="bg-red-500 hover:bg-red-600 rounded-md px-4 py-2 text-base font-semibold text-white">
                        {translations.dashboard.kill}
                    </button>
                </div>

                <div className="flex flex-col gap-3 items-center">
                    <label className="text-base font-bold">{translations.dashboard.view}</label>
                    <button className="bg-purple-500 hover:bg-purple-600 rounded-md px-4 py-2 text-base font-semibold text-white">
                        {translations.dashboard.view}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AttackItem;